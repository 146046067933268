import React from 'react';

const Colony = ({ colonyInfo, setDetailsOpen }) => (
  <div
    style={{
      width: 80,
      height: 60,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      background: `rgba(${255 - colonyInfo.adultCount * 20}, ${
        255 - colonyInfo.litterCount * 20
      }, ${255 - 0}, 1)`,
      border: 'solid 1px black',
    }}
    onClick={(e) => setDetailsOpen(colonyInfo)}
  >
    <div style={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
      {colonyInfo.colonyName}
    </div>
    <div>
      <span style={{ fontSize: '0.8rem' }}>Adults: </span>
      {colonyInfo.adultCount}
    </div>
    <div>
      <span style={{ fontSize: '0.8rem' }}>Litters: </span>
      {colonyInfo.litterCount}
    </div>
  </div>
);

export default Colony;
