import React, { useState } from 'react';
import { Modal } from '@mui/material';
import dayjs from 'dayjs';

import Colony from 'views/Colony';
import ColonyDetails from 'views/ColonyDetails';

const ColonyOverview = ({ colonies, databases }) => {
  const [detailsOpen, setDetailsOpen] = useState(null);

  const calculateColonyInfo = (colonyName) => {
    let adultCount = 0;
    let litterCount = 0;
    const relevantTransactions = [];
    const addRelevant = (dbName, data) =>
      relevantTransactions.push({ database: dbName, ...data });

    databases.forEach((db) => {
      // pups added db should only be relevant to RR tubs
      if (['females added', 'males added', 'pups added'].includes(db.name))
        adultCount += db.data
          .filter((dat) => dat.colony === colonyName)
          .reduce((sum, dat) => {
            addRelevant(db.name, dat);
            return +sum + +dat.count;
          }, 0);
      // not including pups added here because they're counted as litters in this stage of life
      if (['females added', 'males added'].includes(db.name))
        adultCount -= db.data
          .filter(
            (dat) => dat.source === colonyName && !dat.colony.includes('RR')
          )
          .reduce((sum, dat) => {
            addRelevant(db.name, dat);
            return +sum + +dat.count;
          }, 0);
      if (['died'].includes(db.name))
        adultCount -= db.data
          .filter((dat) => dat.colony === colonyName)
          .reduce((sum, dat) => {
            addRelevant(db.name, dat);
            return +sum + +dat.count;
          }, 0);
      // expire the litter after 3 weeks since that's when we move them to RR tubs
      if (['new litter'].includes(db.name))
        litterCount += db.data
          .filter(
            (dat) =>
              dat.colony === colonyName &&
              dayjs().isBefore(dayjs(dat.date).add(3, 'weeks'))
          )
          .reduce((sum, dat) => {
            addRelevant(db.name, dat);
            return +sum + 1;
          }, 0);
    });

    return { colonyName, adultCount, litterCount, relevantTransactions };
  };

  const renderColonyGroup = (colony) => (
    <div key={colony.colonyType} style={{ margin: '20px 0' }}>
      <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>
        {colony.colonyType}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {colony.racks.map(renderRack)}
      </div>
    </div>
  );

  const renderRack = (rack) => (
    <div key={rack} style={{ display: 'flex', flexDirection: 'column' }}>
      {rack.map(renderColony)}
    </div>
  );

  const renderColony = (colonyName) => (
    <Colony
      key={colonyName}
      colonyInfo={calculateColonyInfo(colonyName)}
      setDetailsOpen={setDetailsOpen}
    />
  );

  return (
    <>
      <Modal open={!!detailsOpen} onClose={() => setDetailsOpen(null)}>
        <div
          style={{
            width: 600,
            height: 400,
            background: 'white',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            border: 'solid 2px black',
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Colony colonyInfo={detailsOpen} />
          <ColonyDetails colonyInfo={detailsOpen} />
        </div>
      </Modal>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {colonies.map(renderColonyGroup)}
      </div>
    </>
  );
};

export default ColonyOverview;
