// Used https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=f9414d&secondary.color=4DB6AC
// to figure out colors.

import React from 'react';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';

const themeColors = createTheme({
  palette: {
    primary: {
      contrastText: '#ffffff',
      dark: '#494949',
      main: '#757575',
      light: '#a4a4a4',
    },
    secondary: {
      contrastText: '#ffffff',
      dark: '#000000',
      main: '#212121',
      light: '#484848',
    },
  },
  components: {
    MuiInputBase: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            mx: 1,
          }),
      },
    },
    MuiInputLabel: {
      defaultProps: {
        size: 'small',
      },
    },
  },
});

export default function ThemeProvider(props) {
  return <MuiThemeProvider {...props} theme={themeColors} />;
}
