import React, { useEffect, useState } from 'react';

import ColonyOverview from 'views/ColonyOverview';
// import config from 'config/defaultValues';
// import { useGetSheetQuery } from 'services/sheets';

const Main = () => {
  // const { data: sheetData, isFetching: isFetchingSheet } = useGetSheetQuery();
  // console.log(isFetchingSheet, sheetData);

  const [rawData, setRawData] = useState([]);
  const [data, setData] = useState([]);
  const [databases, setDatabases] = useState([]);
  const [colonies, setColonies] = useState([]);

  const getSheetData = async () => {
    await fetch(
      'https://docs.google.com/spreadsheets/d/e/2PACX-1vQhKCHrVTjmiXKoVkBeiWTcBXKtVkbeIe3gZNQyJnIJgAjGIMqTo38eJw83TmDy0cR8ZWntIDHYXMMU/pub?gid=0&single=true&output=tsv',
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ).then((result) => {
      result.text().then((text) => setRawData(text));
    });
  };

  useEffect(() => {
    getSheetData();
  }, []);

  useEffect(() => {
    // console.log(rawData);
    if (!rawData?.length) return;
    setData(rawData.split('\r\n').map((dat) => dat.split('\t')));
  }, [rawData]);

  useEffect(() => {
    if (!data?.length) return;

    // console.log(data);
    let databaseDefinitions = [
      { name: 'notes', startCol: -1, endCol: -1 },
      { name: 'females added', startCol: -1, endCol: -1 },
      { name: 'males added', startCol: -1, endCol: -1 },
      { name: 'pups added', startCol: -1, endCol: -1 },
      { name: 'new litter', startCol: -1, endCol: -1 },
      { name: 'died', startCol: -1, endCol: -1 },
      { name: 'pups ready to move', startCol: -1, endCol: -1 },
    ];
    databaseDefinitions = databaseDefinitions.map((dataDef) => {
      data[0].forEach((col, index) => {
        if (col.toLowerCase() === dataDef.name) dataDef.startCol = index + 1;
        if (dataDef.startCol > -1) {
          if (dataDef.endCol > -1) return;
          if (col === '') dataDef.endCol = index;
          if (index === data[0].length - 1) dataDef.endCol = index;
        }
      });

      return { ...dataDef };
    });
    // console.log(data, databaseDefinitions);

    const _databases = databaseDefinitions.map((dataDef) => {
      const columnNames = data
        .slice(0, 1)
        .map((row) => row.slice(dataDef.startCol, dataDef.endCol))[0];
      return {
        name: dataDef.name,
        columnNames,
        data: data.slice(1, data.length).map((row) => {
          const cols = row.slice(dataDef.startCol, dataDef.endCol);
          const obj = {};
          columnNames.forEach((colNam, index) => (obj[colNam] = cols[index]));
          return obj;
        }),
      };
    });
    // console.log(_databases);

    setDatabases(_databases);

    const _colonies = ['breeders', 'growouts'].map((colonyType) => {
      const prefix =
        colonyType === 'breeders'
          ? 'RB'
          : colonyType === 'growouts'
          ? 'RR'
          : 'unknowncolonyname';
      const columns = [];
      const rows = [];
      let typeStartCol = -1;
      let typeEndCol = -1;
      let typeEndRow = -1;
      data.slice(0, 1).map((row) =>
        row.slice(0, row.length).forEach((col, index) => {
          if (col.toLowerCase() === colonyType) typeStartCol = index;
          if (typeStartCol > -1 && index > typeStartCol && col === '')
            typeEndCol = index;
          if (typeEndCol > -1) return;
          if (typeStartCol > -1 && index > typeStartCol) columns.push(col);
        })
      );
      data.slice(1, data.length).forEach((row, index) => {
        if (row[typeStartCol] === '') typeEndRow = index;
        if (typeEndRow > -1) return;
        rows.push(row[typeStartCol]);
      });
      // console.log(columns, rows);
      return {
        colonyType,
        racks: columns.map((col) =>
          rows.map((row) => `${prefix}${col}-${row}`)
        ),
        // .flat(),
      };
    });
    // console.log(_colonies);

    setColonies(_colonies);
  }, [data]);

  return <ColonyOverview colonies={colonies} databases={databases} />;
};

export default Main;
