import React from 'react';

import Colony from 'views/Colony';

const ColonyDetails = ({ colonyInfo }) => {
  const renderData = (data, index) => (
    <div
      key={index}
      style={{ display: 'flex', flexDirection: 'row', gap: 20, marginTop: 5 }}
    >
      {Object.entries(data).map((entry) => (
        <span key={entry}>
          {entry[0]} <b>{entry[1]}</b>
        </span>
      ))}
    </div>
  );

  return (
    <div style={{ overflow: 'auto' }}>
      {colonyInfo?.relevantTransactions?.reverse()?.map(renderData)}
    </div>
  );
};

export default ColonyDetails;
