import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ThemeProvider from 'components/ThemeProvider';
import Main from 'views/main';

import './App.scss';

const App = () => (
  <ThemeProvider>
    <BrowserRouter>
      <Routes>
        <Route index element={<Main />} />
        <Route path="/" element={<Main />} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>
);

export default App;
