import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// import config from 'config/defaultValues';

const sheetsApi = createApi({
  reducerPath: 'sheets',
  baseQuery: fetchBaseQuery({
    // baseUrl: config.SHEET_URL,
    // baseUrl:
    // 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQhKCHrVTjmiXKoVkBeiWTcBXKtVkbeIe3gZNQyJnIJgAjGIMqTo38eJw83TmDy0cR8ZWntIDHYXMMU/pub?gid=0&single=true&output=tsv',
    baseUrl: 'https://docs.google.com/spreadsheets/d/e',
    prepareHeaders: (headers) => {
      // headers.set('accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['Sheets'],
  endpoints: (builder) => ({
    getSheet: builder.query({
      query: () => ({
        url: '/2PACX-1vQhKCHrVTjmiXKoVkBeiWTcBXKtVkbeIe3gZNQyJnIJgAjGIMqTo38eJw83TmDy0cR8ZWntIDHYXMMU/pub?gid=0&single=true&output=tsv',
        method: 'GET',
      }),
      providesTags: ['Sheets'],
    }),
  }),
});

export { sheetsApi };
export const { useGetSheetQuery } = sheetsApi;
